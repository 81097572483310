import styles from 'components/PRISMIC/scss/pages.module.scss';
import { cloneElement, isValidElement } from 'react';

export enum Alignment {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
  Justified = 'Justified'
}

const utils = {
  getAlignClass: (field: Alignment): string => {
    switch (field) {
      case Alignment.Left:
        return 'text-left';
      case Alignment.Center:
        return 'text-center';
      case Alignment.Right:
        return 'text-right';
      case Alignment.Justified:
        return 'text-justify';
      default:
        return '';
    }
  },

  getPagesClassByName(name: string) {
    switch (name) {
      case 'callout':
        return styles['pages-callout'];
      case 'footnote':
        return styles['pages-footnote'];
      case 'giant':
        return styles['pages-giant-text'];
      case 'promo':
        return styles['pages-promo'];
      case 'quote':
        return styles['pages-quote'];
      case 'indented':
        return styles['pages-copy--indented'];
      case 'letter':
        return styles['pages-copy--letter'];
      case 'hero':
        return styles['pages-hero'];
      case 'body':
      default:
        return '';
    }
  },

  //[button ...] shortcodes were found in use from Roadrunner, no others so far
  parseShortcodes(reactNode: React.ReactNode): React.ReactNode {
    if (typeof reactNode === 'string') {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: this.parseStringWithShortcodes(reactNode)
          }}
        />
      );
    } else if (isValidElement(reactNode)) {
      return cloneElement(
        reactNode,
        {},
        this.parseShortcodes(
          (reactNode as React.ReactElement<{ children: React.ReactNode }>).props
            .children
        )
      );
    } else if (Array.isArray(reactNode)) {
      return reactNode.map(child => this.parseShortcodes(child));
    }

    return reactNode;
  },
  parseStringWithShortcodes(content: string): string {
    return content.replace(
      /(\s*\[)(\w+.*?)(][^(]?\s*)/g,
      (match: string, opener, innerContent: string) => {
        if (!innerContent.startsWith('button')) return match;
        const attributes: Record<string, string | boolean> = {};

        // Key=value tokens with a quoted string (e.g. foo="hello")
        innerContent.replace(/(\S+)="(.*?)"/g, (match, key, value) => {
          attributes[key] = value;
          return '';
        });

        // Key-value tokens with a digit, e.g. foo=123
        innerContent.replace(/(\S+)=(\d+)/g, (match, key, value) => {
          attributes[key] = value;
          return '';
        });

        // Widow tokens, e.g. foo
        innerContent.replace(/(\S+)/g, key => {
          attributes[key] = true;
          return '';
        });

        const customClasses = ['small', 'narrow', 'dark', 'blue', 'red']
          .filter(it => attributes[it])
          .map(it => styles[`button--${it}`])
          .join(' ');

        // This may be used as markdown so whitespace is important.
        // Note: Removed Whitespace as it was causing a bug
        return `<a class="${styles.button} ${customClasses} mt-4" style="text-decoration:none;" href="${attributes.url?.toString() || attributes.href?.toString()}" target="${attributes.target?.toString() || '_self'}">${attributes.label || 'Go'}</a>
        `;
      }
    );
  }
};

export default utils;
