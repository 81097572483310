import { asText, Content, isFilled } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import { crush } from '@tedconf/js-crushinator-helpers';
import { default as classNames, default as cx } from 'classnames';
import { MarkdownSliceDefaultPrimary } from 'components/../../prismicio-types';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import { PageBuilderHeading } from 'components/PRISMIC/slices/Heading/Heading';
import { marked, Parser, Tokens } from 'marked';
import { renderToString } from 'react-dom/server';
import utils, { Alignment } from '../../utils';

import type { JSX } from 'react';

/**
 * Props for `Markdown`.
 */
export type MarkdownProps = SliceComponentProps<Content.MarkdownSlice>;

interface RendererContext {
  parser: Parser;
}

// Override function with new renderer API
const renderer = {
  image(this: RendererContext, token: Tokens.Image): string {
    return renderToString(
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={crush(token.href || '')}
        className={styles.image}
        title={token.title || ''}
        alt={token.text}
      />
    );
  },
  paragraph(this: RendererContext, token: Tokens.Paragraph): string {
    const text = this.parser.parseInline(token.tokens);
    if (text.startsWith('<a') && text.endsWith('</a>')) return text;
    return renderToString(
      <p dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br/>') }} />
    );
  },
  heading(this: RendererContext, token: Tokens.Heading): string {
    const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
    const level = headingLevels[Math.min(token.depth, 5)];
    const text = this.parser.parseInline(token.tokens);
    return renderToString(
      <PageBuilderHeading
        dangerouslySetInnerHTML={{ __html: text }}
        level={level}
      />
    );
  }
};

const MarkedRenderer = ({
  children,
  ...props
}: {
  children: string;
  className?: string;
}): JSX.Element => {
  const mdParsedWithShortcodes = utils.parseStringWithShortcodes(children);

  const parsedMarkdown = marked
    .use({ renderer })
    .parse(mdParsedWithShortcodes as string, {
      breaks: true,
      pedantic: true
    }) as string;

  return (
    <span {...props} dangerouslySetInnerHTML={{ __html: parsedMarkdown }} />
  );
};

// This is taken straight from Roadrunner as-is.
const calculateGridClasses = (
  cellAmount: number,
  gridStyle: MarkdownSliceDefaultPrimary['column_style']
): { equal?: string[]; thin?: string; wide?: string } => {
  // Max cell-count of 12.
  cellAmount = Math.min(cellAmount, 12);

  if (cellAmount <= 0) return {};

  const baseSpan = Math.floor(12 / cellAmount);

  if (gridStyle === 'Equal-width columns' && cellAmount === 5) {
    // Use n-up grids for equal rows of 5 items
    return {
      equal: [styles[`col`]]
    };
  }

  if (gridStyle === 'Equal-width columns' || cellAmount > 6) {
    // Base case: All columns should be of equal width
    return {
      equal: [styles[`col-xs-${baseSpan}`]]
    };
  }

  let wide_span, thin_span;
  switch (cellAmount) {
    case 1:
      wide_span = 12;
      thin_span = 12;
      break;
    case 2:
      wide_span = 8;
      thin_span = 4;
      break;
    case 3:
      wide_span = 6;
      thin_span = 3;
      break;
    case 4:
      wide_span = 6;
      thin_span = 2;
      break;
    case 5:
      wide_span = 6;
      thin_span = 1;
      break;
    case 6:
      wide_span = 8;
      thin_span = 1;
      break;
  }
  return {
    wide: styles[`col-xs-${wide_span}`],
    thin: styles[`col-xs-${thin_span}`]
  };
};

function getColumnCssClass(
  cellAmount: number,
  columnStyle: MarkdownSliceDefaultPrimary['column_style'],
  index: number
) {
  const allColumnClasses = calculateGridClasses(cellAmount, columnStyle);
  if (allColumnClasses.equal) return allColumnClasses.equal;

  if (columnStyle === 'Wide leftmost column' && index === 0)
    return allColumnClasses.wide;

  if (columnStyle === 'Wide middle column' && index === 1)
    return allColumnClasses.wide;

  if (columnStyle === 'Wide rightmost column' && index === cellAmount - 1)
    return allColumnClasses.wide;

  return allColumnClasses.thin;
}

/**
 * Component for "Markdown" Slices.
 */
const Markdown = ({ slice }: MarkdownProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={classNames(
        slice.items.length > 1 ||
          ['callout', 'promo', 'footnote'].includes(slice.primary.text_style)
          ? styles['pages-module']
          : null,
        styles.markdownField
      )}
    >
      {isFilled.richText(slice.primary.heading) && (
        <PageBuilderHeading level="default">
          <PrismicRichText field={slice.primary.heading} />
        </PageBuilderHeading>
      )}
      <div
        className={cx(
          styles.row,
          slice.items.length === 5 && [
            styles['row-xs-2up'],
            styles['row-lg-5up']
          ],
          utils.getPagesClassByName(slice.primary.text_style)
        )}
      >
        {slice.items.map((row, i) => {
          const columnClass = getColumnCssClass(
            slice.items.length,
            slice.primary.column_style,
            i
          );

          return (
            <div
              key={i}
              className={classNames(
                columnClass,
                utils.getAlignClass(Alignment[slice.items[i].align])
              )}
            >
              <MarkedRenderer
                className={cx(
                  'prismic-markdown [&_hr]:mb-5 [&_img]:mb-3 [&_img]:inline [&_img]:max-w-full'
                )}
              >
                {asText(row.column)
                  // The old page builder allowed lots of invalid markdown, this is an attempt to catch as many cases as possible
                  // * Links with newlines or spaces inbetween
                  .replace(/]\s+\(/g, '](')
                  // * Links with newlines *inside* the links themselves
                  .replace(/(\[[^\[\]\n]*]\([^)\s]*)\s*/g, '$1')
                  // * Add <hr> when 3 or more dashes appear
                  .replace(/-{3,}/g, '<hr />')
                  // * Add <hr> when 3 or more underscores appear
                  .replace(/_{3,}/g, '<hr />')}
              </MarkedRenderer>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Markdown;
