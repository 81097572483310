import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import { DOMAttributes, type JSX, type ReactNode } from 'react';

/**
 * Props for `Heading`.
 */
export type HeadingProps = SliceComponentProps<Content.HeadingSlice>;

function slugify(input: string) {
  if (!input) return '';
  // make lower case and trim
  let slug = input.toLowerCase().trim();
  // remove accents from charaters
  slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  // replace invalid chars with spaces
  slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();
  // replace multiple spaces or hyphens with a single hyphen
  slug = slug.replace(/[\s-]+/g, '-');
  return slug;
}

// This logic comes straight from Roadrunner + Page Builder, as is
export const HEADING_CLASSES = {
  h1: [styles.h1, styles.m4],
  h2: [styles.h3, styles.m4],
  h3: [styles.h4, styles.m4],
  h4: [styles.h7, styles.m5],
  h5: [styles.h10, styles.m5],
  h6: [styles.h12, styles.m5],
  default: [styles.h4, styles.m3]
};

export const SMALL_HEADING_CLASSES = {
  h1: [styles.h3, styles.m4],
  h2: [styles.h4, styles.m4],
  h3: [styles.h7, styles.m5],
  h4: [styles.h10, styles.m5],
  h5: [styles.h12, styles.m5],
  h6: [styles.h12, styles.m5],
  default: [styles.h4, styles.m3]
};

type PageBuilderHeadingProps = {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'default';
  useSmallerClasses?: boolean;
  children?: ReactNode;
  id?: string;
} & DOMAttributes<HTMLHeadingElement>;

export const PageBuilderHeading = ({
  level,
  children,
  useSmallerClasses = false,
  id,
  ...props
}: PageBuilderHeadingProps): JSX.Element => {
  const headingClass: string[] =
    (useSmallerClasses
      ? SMALL_HEADING_CLASSES[level]
      : HEADING_CLASSES[level]) || [];

  const CustomTag = level === 'default' ? 'h3' : level;

  return (
    <CustomTag
      {...props}
      id={id ? `${CustomTag}--${id}` : ''}
      className={headingClass.join(' ')}
    >
      {children}
    </CustomTag>
  );
};

/**
 * Component for "Heading" Slices.
 */
const Heading = ({ slice }: HeadingProps): JSX.Element => {
  return (
    <PageBuilderHeading
      id={slugify(slice.primary.text || '')}
      level={slice.primary.level}
      useSmallerClasses={true}
    >
      {slice.primary.text}
    </PageBuilderHeading>
  );
};

export default Heading;
